<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="listQuery.class_grade_update_at"
        type="date"
        class="filter-item"
        value-format="yyyy-MM-dd"
        format="MM-dd"
        placeholder="班级自动升级时间"
      >
      </el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleTime">
        保存
      </el-button>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      listQuery: {
        class_grade_update_at: "",
      },
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    handleTime() {
      request({
        url: "/api/schoolend/school/store",
        method: "post",
        data: {
          class_grade_update_at: this.listQuery.class_grade_update_at,
        },
      }).then((res) => {
        this.$message({
          type: "success",
          message: "保存成功",
        });
        this.getInfo();
      });
    },
    getInfo() {
      request({
        url: "/api/schoolend/admin/info",
        method: "get",
      }).then((res) => {
        this.listQuery.class_grade_update_at =
          res.data.user.class_grade_update_at;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>